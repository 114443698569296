import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

// listen for scroll to events
if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  var scroll = require('smooth-scroll');
  new scroll('a[href*="#"]', {
    speed: 500,
    offset: 90,
  });
}
